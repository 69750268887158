<template>
  <div id="NewsDetail">
    <Navbar>
      <span> {{ $t('navBarMoomall.moomallNewsDetail') }}</span>
    </Navbar>
    <v-main>
      <BaseImage :pathImage="NewsDetail.image_id" />
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <div class="d-flex justify-start">
              <BaseIcon nameIcon="Icon-calendar" cssIcon="ma-2" />
              <div
                class="my-auto"
                v-text="created_at(NewsDetail.created_at)"
              ></div>
              <v-spacer></v-spacer>
              <v-chip
                color="#D11515"
                class="my-auto"
                x-small
                dark
                v-if="NewsDetail.is_new && timeOut(NewsDetail.created_at) > 0"
              >
                {{ $t('mooMallNews.new') }}
              </v-chip>
            </div>
          </v-col>

          <v-col cols="12" v-if="isEvent">
            <div class="event">
              <v-btn
                color="#D33737"
                @click="redirectTo()"
                text-color
                dark
                class="event-btn"
              >
                {{ this.event.name }}
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12">
            <div
              class="black--text text-justify bg-white body-text"
              v-html="
                $i18n.locale === 'th_TH'
                  ? NewsDetail.body_th
                  : NewsDetail.body_en
              "
            ></div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import NewsService from '@/services/NewsService'
import EventServices from '@/services/EventServices'

export default {
  name: 'NewsDetail',
  data() {
    return {
      NewsDetail: {},
      event: {},
    }
  },
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
  },
  methods: {
    created_at(local) {
      var date = new Date(local)
      var dateFormat = date.toLocaleDateString(
        this.$i18n.locale === 'th_TH' ? 'th-TH' : 'en-EN',
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        },
      )
      return dateFormat
    },
    timeOut(expireTime) {
      const timeOutExpire = new Date(expireTime.replace(' ', 'T'))
      const expTime = 3 * 24 * 60 * 60 * 1000 // day * 24 * 60 * 60 * 1000
      const ExpireTime = timeOutExpire.getTime() + expTime
      const timeout = ExpireTime - new Date().getTime()
      return timeout
    },
    getEvent() {
      if (!this.isEvent) {
        return null
      }

      EventServices.getEvents()
        .then(res => {
          if (res.status === 200) {
            this.event = res.data.filter(
              item => item.active && item.id == this.NewsDetail.event_id,
            )[0]
          }
        })
        .catch(err => {
          this.$swal.fire({
            icon: 'error',
            title: `${err.response.data.message}`,
          })
          if (err.response.status === 401) {
            this.removeToken()
            this.$router.push({ name: 'Admin' })
          }
        })
    },
    redirectTo() {
      this.$router.push(this.event.path)
    },
  },
  computed: {
    isEvent() {
      return this.NewsDetail.event_id != 0
    },
  },
  created() {
    NewsService.getNew(this.id)
      .then(resp => {
        if (resp.status === 200) {
          this.NewsDetail = resp.data || {}
          this.getEvent()
        }
      })
      .catch(err => {
        this.$swal
          .fire({
            icon: 'error',
            title: `${err.message}`,
            showDenyButton: false,
          })
          .then(result => {
            if (result.isConfirmed) {
              this.$router.push({ name: 'News' })
            }
          })
      })
  },
}
</script>

<style lang="scss" scoped>
#NewsDetail {
  background-color: #ffffff;
  height: 100%;
}

.body-text {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.event {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;

  &-btn {
    display: block;
    align-items: flex-end;
  }
}

// justify-content: flex-end;
</style>
